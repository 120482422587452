const ptTranslation = {
  show_all_fields: "Mostrar todos os campos",
  response_modal_add_another: "Salvar e adicionar a próxima",  
  home_button_text: "Continuar",
  home_text:
    "Antes de continuar, porfavor certifique-se que você não é um robô.",
  select_project_first_text: "Obrigado por estar participando do Knowmad Café!",
  select_project_second_text:
    "Porfavor selecione seu projeto e local de reunião",
  select_project_button: "Continuar",
  select_project_input: "Seu endereço de e-mail",
  required_field_error: "Esse campo é obrigatório.",
  select_project_first_select: "Projeto",
  select_project_second_select: "Site",
  default_placeholder_site: "Selecione um site antes",
  first_text_response_screen: "Respostas do meu grupo",
  null_text_response_screen: "Nenhuma resposta gravada até agora!",
  add_response_button: "Adicionar nova",
  not_found_join_screen: "Esse projeto não foi encontrado.",
  project_inactive_join_screen: "Este projeto não está mais ativo",
  first_text_join_screen: "Dados do projeto",
  response_modal_first_test: "Entrada de dados",
  response_modal_save_button: "Salvar",
  select_project_not_found_projects_text: "Nenhum projeto foi encontrado.",
  select_project_error_button: "Voltar ao início",
  first_text_modal_response_screen: "Pergunta",
  irst_text_modal_tooltip_response_screen: "Oi! eu sou um tooltip.",
  question_text_modal_response_screen: "Selecione uma pergunta",
  response_text_modal_response_screen: "Sua resposta",
  placeholder_response:
    "Escreva sua resposta para a pergunta selecionada aqui...",
  priority_text_modal_response_screen: "Prioridade de resposta",
  is_profund_text_modal_response_screen:
    "Esta resposta é particularmente profunda?",
  notes_text_modal_response_screen: "Notas e comentários (opcional)",
  placeholder_notes: "Notas e comentários",
  image_picker_text_modal_response_screen:
    "Por favor, compartilhe qualquer imagem relacionada à sua resposta (opcional)",
  image_picker_button_text_modal_response_screen: "Selecionar arquivo",
  saving_response: "Estamos salvando sua resposta, espere um minuto.",
  tag_text_modal_response_screen: "Tags",
  tag_placeholder_modal_response_screen: "Adicionar tags",
  response_created_success: "Resposta criada com sucesso!",
  response_updated_success: "Resposta atualizada com sucesso!",
  response_error: "Ocorreu um erro em sua resposta.",
  questions_not_found:
    "O administrador ainda não cadastrou questões para esse evento, tente mais tarde.",

  //Commom texts
  back_text: "back",
  yes: "Sim",
  no: "Não",
  low: "Baixo",
  medium: "Médio",
  high: "Alto",
  lets_start: "Vamos começar!",

  //Tooltips
  question_tooltip: "Selecione a pergunta relacionada a esta resposta.",
  response_tooltip:
    "Escreva a resposta do seu grupo para a pergunta aqui. Se o seu grupo tiver mais de uma ideia ou resposta para a pergunta, insira cada uma separadamente.",
  priority_tooltip:
    " Indique o quão importante esta resposta foi para o grupo.",
  is_deep_tooltip:
    "Se você acha que esta resposta fornece uma ótima visão, indique aqui.",
  notes_tooltip: "Use este espaço para incluir comentários adicionais, etc. ",
  tag_tooltip:
    "Digite um algumas tags para ajudar a categorizar esta resposta.",
  attachments_tooltip:
    "Você pode enviar nesse campo fotos que ajudem a descrever sua resposta.",

  //Instructions modal
  instructions: "Começando",
  instructions_modal_p: "Para ",
  instructions_modal_first_strong: "cada ideia ",
  instructions_modal_first_p:
    "apresentado pelo seu grupo de mesa, clique em adicionar novo para documentar suas respostas. Você pode postar quantas respostas seu grupo desejar.",
  instructions_modal_second_p:
    "Por favor, certifique-se de classificar a prioridade da resposta, e, se uma ideia parecer realmente profunda ao grupo, merecendo atenção extra, por favor, indique isso.",

  //Privacy policy button
  privacy_policy_pre_text: "Clicando em continuar você concorda com nossa",
  privacy_policy: "Política de privacidade",

  //Modal delete confirmation
  confirm_modal_title: "Confirmação de deleção",
  confirm_modal_text:
    "Você realmente deseja deletar essa resposta? essa ação será irreversível.",

  //Modal close confirmation
  confirm_modal_close_title: "Confirmação de saída.",
  confirm_modal_close_text:
    "Sua resposta ainda não está salva, você realmente deseja fechar? ",
};

export default ptTranslation;
