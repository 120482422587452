import React from "react";
import { Props } from "./types";
import { BsQuestionCircle } from "react-icons/bs";
import { Box, Icon, Tooltip } from "@chakra-ui/react";

const StyledTooltip: React.FC<Props> = ({ text, onClick }: Props) => {
  return (
    <Tooltip
      hasArrow
      placement={"top"}
      label={text}
      bg="gray.300"
      color="black"
    >
      <Box onClick={onClick} cursor={onClick ? "pointer" : "initial"}>
        <Icon as={BsQuestionCircle} size={"12px !important"} display={"flex"} />
      </Box>
    </Tooltip>
  );
};

export default StyledTooltip;
