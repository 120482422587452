import { VStack, Text, HStack, Icon } from "@chakra-ui/react";
import React from "react";
import { StyledCheckButton } from "./styles";
import { Props } from "./types";
import { MdOutlineErrorOutline } from "react-icons/md";
import StyledTooltip from "../../../../components/base/Tooltip";

const MultipleCheckbox: React.FC<Props> = ({
  title,
  onChange,
  options,
  value,
  error,
  primary_collor,
  tooltip_text,
}: Props) => {
  return (
    <VStack w={"100%"} alignItems={"left"} padding={"16px 0px"}>
      <HStack>
        <Text
          fontSize={"14px"}
          fontWeight={"400"}
          color={"place_holder_texts"}
          textAlign={"left"}
        >
          {title}
        </Text>
        <StyledTooltip text={tooltip_text} />
      </HStack>
      <HStack w={"100%"} spacing={"12px"}>
        {options.map((option, index) => (
          <StyledCheckButton
            key={index}
            isCheked={option.value === value}
            primary_collor={primary_collor ? primary_collor : "#000000"}
            onClick={() => onChange && onChange(option.value)}
          >
            <Text fontSize={"14px"} fontWeight={"400"} textAlign={"left"}>
              {option.label}
            </Text>
          </StyledCheckButton>
        ))}
      </HStack>

      {error && (
        <HStack mt={"14px"} alignItems={"center"}>
          <Icon
            as={MdOutlineErrorOutline}
            color={"error"}
            size={"10px !important"}
            marginBottom={"2px !important"}
          />
          <Text
            fontSize={"14px"}
            fontWeight={"400"}
            color={"error"}
            textAlign={"left"}
            marginLeft={"4px !important"}
          >
            {error}
          </Text>
        </HStack>
      )}
    </VStack>
  );
};

export default MultipleCheckbox;
