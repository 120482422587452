import styled from "styled-components";
import { space, layout } from "styled-system";

export const StyledModal = styled.div<any>`
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  max-height: 100vh;
  z-index: 10;
  margin: 0px !important;
`;

export const StyledModalContent = styled.div<any>`
  margin: auto;
  height: 100%;
  width: 100vw;
  max-height: 100vh;
  padding: ${(props) => (props.isMobile ? "0px" : "20px")};
  overflow: auto;
  max-width: 100%;
  border-radius: ${(props) => (props.isMobile ? "0px" : "12px")};
  background-color: #ffffff;
  position: relative;
  overflow: hidden;

  @media (min-width: 875px) {
    max-width: 670px;
    height: auto;
  }

  ${space}
  ${layout}
`;

export const StyledCloseButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  cursor: pointer;
  transition: all 300ms;
  border-radius: 8px;
  position: absolute;
  right: 32px;
  top: 32px;

  &:hover {
    background-color: "#5d5d5d";
  }
`;
