import { VStack, Text, HStack, Icon } from "@chakra-ui/react";
import React from "react";
import { StyledInput } from "./styles";
import { Props } from "./types";
import { MdOutlineErrorOutline } from "react-icons/md";
import StyledTooltip from "../../../../components/base/Tooltip";

export const InputResponse: React.FC<Props> = React.forwardRef(
  ({ ...props }, ref: any) => {
    return (
      <VStack
        w={"100%"}
        borderBottom={"1px solid #F2F2F2"}
        alignItems={"left"}
        padding={"16px 0px"}
      >
        <HStack>
          <Text
            fontSize={"14px"}
            fontWeight={"400"}
            color={"place_holder_texts"}
            textAlign={"left"}
          >
            {props?.title}
          </Text>
          <StyledTooltip text={props.tooltip_text} />
        </HStack>
        <StyledInput {...props} ref={ref} />

        {props.error && (
          <HStack mt={"14px"} alignItems={"center"}>
            <Icon
              as={MdOutlineErrorOutline}
              color={"error"}
              size={"10px !important"}
              marginBottom={"2px !important"}
            />
            <Text
              fontSize={"14px"}
              fontWeight={"400"}
              color={"error"}
              textAlign={"left"}
              marginLeft={"4px !important"}
            >
              {props.error}
            </Text>
          </HStack>
        )}
      </VStack>
    );
  }
);

export default InputResponse;
