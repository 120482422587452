import styled from "styled-components";

export const StyledResponseButton = styled.button`
  padding: 8px 0px;
  width: 100%;
  justify-content: space-between;
  display: flex;
  align-items: center;
  transition: 300ms all;
  border-bottom: 1px solid transparent;
  &:hover {
    border-bottom: 1px solid #f2f2f2;
  }
`;
