import styled, { css } from "styled-components";
import { margin, width } from "styled-system";

export const StyledOutsideComponent = styled.div<any>`
  width: 100%;
  ${margin};
  ${width};
`;

export const StyledBoxInput = styled.div`
  position: relative;
  width: 100%;
`;

export const StyledErrorMessage = styled.label`
  color: "red";
  text-align: left;
  font-family: "Inter", sans-serif;
  margin-left: 4px;
  font-size: 14px;
  width: auto;
`;

export const StyledPlaceHolder = styled.div<{ inInput: boolean }>`
  padding-top: ${(props) => (props.inInput ? "8px" : "16px")};
  transition: all 300ms;
  position: absolute;
  padding-left: 16px;
  border-radius: 8px;
  left: 0;
  top: 0;
`;

export const StyledCounter = styled.div`
  justify-content: right;
  position: absolute;
  display: flex;
  right: 16px;
  top: 10px;
`;
export const StyledIconContainer = styled.div`
  justify-content: right;
  position: absolute;
  display: flex;
  right: 16px;
  top: 16px;
  z-index: 5;
`;

export const StyledInput = styled.input<any>`
  width: 100%;
  padding: ${(props) =>
    props.placeHolder && props.inInput ? "24px 20px 8px 15px" : "16px 20px"};
  font-family: "Inter", sans-serif;
  border: ${(props) => (props.borderless ? "none" : "1px solid #D5D5DC")};
  background: ${(props) => (!props.disabled ? "transparent" : "#212121")};
  transition: all 300ms;
  border-radius: 8px;
  color: ${(props) => (!props.disabled ? "#212121" : "#212121")};
  font-size: 14px;

  ${(props) =>
    props.onClick &&
    css`
      cursor: pointer;
      &:hover {
        border: ${props.borderless ? "none" : "1px solid #000000"};
      }
    `}

  &:focus {
    border: ${(props) =>
      props.borderless
        ? "none"
        : props.hasError
        ? `1px solid #55555D`
        : `1px solid #000000`};
    outline: none;
  }

  &::placeholder {
    color: "#4D4D4D";
  }

  ${(props) =>
    props.hasError &&
    css`
      border: ${props.bordless ? "none" : "1px solid red"};
    `}
`;
