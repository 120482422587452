const czTranslation = {
  show_all_fields: "zobrazit všechna pole",
  response_modal_add_another:"Uložit a přidat další",
  home_button_text: "Pokračovat",
  home_text: "Než začneme, potvrďte, že nejste robot.",
  select_project_first_text: "Děkujeme za účast v Knowmad Café!",
  select_project_second_text: "Vyberte prosím svůj projekt a místo schůzky.",
  select_project_button: "Pokračovat",
  select_project_input: "Vaše e-mailová adresa",
  required_field_error: "Toto pole je povinné.",
  select_project_first_select: "Projekt",
  select_project_second_select: "Umístění",
  default_placeholder_site: "Vyberte svou polohu",
  first_text_response_screen: "Odpovědi mé skupiny",
  null_text_response_screen: "Zatím nebyly zaznamenány žádné odpovědi!",
  add_response_button: "Přidat nový",
  not_found_join_screen: "Tento projekt nelze nalézt.",
  project_inactive_join_screen: "Tento projekt již není aktivní.",
  first_text_join_screen: "Data projektu",
  response_modal_first_test: "Zadání odpovědi entry",
  response_modal_save_button: "Uložit",
  select_project_not_found_projects_text: "Nebyly nalezeny žádné projekty",
  select_project_error_button: "Zpět na domovskou stránku",
  first_text_modal_response_screen: "Otázka",
  question_text_modal_response_screen: "Vyberte otázku",
  response_text_modal_response_screen: "Odpověď skupiny",
  placeholder_response: "Sem napište odpověď své skupiny na vybranou otázku...",
  priority_text_modal_response_screen: "Priorita odezvy",
  is_profund_text_modal_response_screen: "Hluboký?",
  notes_text_modal_response_screen: "Poznámky a komentáře (volitelné)",
  placeholder_notes: "Poznámky a komentáře",
  image_picker_text_modal_response_screen: "Nahrát obrázek (volitelné)",
  image_picker_button_text_modal_response_screen:
    "Nahrát obrázek (volitelné) file",
  saving_response: "Okamžik. Vaše odpověď se ukládá&#8230;",
  tag_text_modal_response_screen: "Značky",
  tag_placeholder_modal_response_screen: "Přidat značku",
  response_created_success: "Vaše odpověď byla úspěšně uložena.",
  response_updated_success: "Vaše odpověď byla úspěšně aktualizována.",
  response_error: "V poli je chyba.",
  questions_not_found:
    "Administrátor ještě nezaregistroval otázky pro tuto událost, zkuste to prosím později.",

  //Commom texts
  back_text: "zpět",
  yes: "Ano",
  no: "Ne",
  low: "Nízká",
  medium: "Střední",
  high: "Vysoká",
  lets_start: "Začněme!",

  //Tooltips
  question_tooltip: "Vyberte otázku spojenou s touto odpovědí.",
  response_tooltip:
    "Sem napište odpověď své skupiny na otázku. Pokud má vaše skupina více než jeden nápad nebo odpověď na otázku, zadejte prosím každou zvlášť.",
  priority_tooltip: "Uveďte, jak důležitá byla tato odpověď pro skupinu.",
  is_deep_tooltip:
    "Pokud máte pocit, že tato odpověď poskytuje skvělý přehled, uveďte to zde.",
  notes_tooltip:
    "Tento prostor použijte k zahrnutí jakýchkoli dalších poznámek, komentářů atd. souvisejících s touto odpovědí.",
  tag_tooltip:
    "Zadejte několik značek, které pomohou kategorizovat tuto odpověď.",
  attachments_tooltip:
    "Do tohoto pole můžete nahrát fotografie, které pomohou ilustrovat reakci skupiny.",
  tooltip_instructions_modal: "Kliknutím zobrazíte pokyny",

  //Instructions_modal
  instrukce_modal_title: "Začínáme",
  instructions_modal_p: "Pro ",
  instructions_modal_first_strong: "každý nápad ",
  instructions_modal_first_p:
    "ředložený vaší skupinou tabulek klikněte na „přidat nový“ a zdokumentujte své odpovědi. Můžete zasílat tolik odpovědí, kolik si vaše skupina přeje",
  instructions_modal_second_p:
    "Ujistěte se, že ohodnotíte prioritu odpovědi, a pokud je nápad na skupinu skutečně hluboký a zaslouží si zvláštní pozornost, uveďte to.",

  //Privacy policy button
  privacy_policy_pre_text: "Kliknutím na pokračovat souhlasíte s našimi",
  privacy_policy: "zásady ochrany osobních údajů",

  //Modal delete confirmation
  confirm_modal_title: "Potvrdit smazání",
  confirm_modal_text:
    "Opravdu chcete smazat tuto odpověď? Tuto akci nelze vrátit zpět.",

  //Modal close confirmation
  confirm_modal_close_title: "Potvrdit ukončení",
  confirm_modal_close_text:
    "Vaše odpověď ještě není uložena, opravdu chcete zavřít?",
};

export default czTranslation;
