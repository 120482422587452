import {
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { InfinitySpin } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { ProjectContext } from "../../contexts/project-context";
import AttachmentService from "../../services/attachment.service";
import QuestionService from "../../services/question.service";
import ResponseService from "../../services/response.service";
import TagService from "../../services/tag.service";
import Button from "../../components/base/Button";
import {
  IAttachment,
  IQuestionIdentifier,
  IResponseFormatted,
} from "../../types";
import ResponseForm from "./ResponseForm";
import * as _ from "lodash";

import { motion } from "framer-motion";
import ResponseCard from "./ResponseCard";
import i18next from "../../i18n";
import StyledTooltip from "../../components/base/Tooltip";

const ResponsePage: React.FC = () => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState<
    Partial<IResponseFormatted> | undefined
  >();
  const [submitingResponse, setSubmitingResponse] = useState(false);
  const [responses, setResponses] = useState<IResponseFormatted[] | []>([]);
  const [responseIsLoading, setResponseIsLoading] = useState(true);
  const { projectSelected, defaultTags, populateTags } =
    useContext(ProjectContext);
  const [projectQuestions, setProjectQuestions] = useState<
    IQuestionIdentifier[] | undefined
  >();
  const [instructionsModal, setInstructionsModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState<boolean | any>();

  const toast = useToast();
  const navigate = useNavigate();
  const currentLanguage = i18next.languages[0];

  useEffect(() => {
    if (!projectSelected) {
      navigate("/");
    }
  }, [projectSelected, navigate]);

  const populateResponses = async () => {
    try {
      if (projectSelected) {
        setResponseIsLoading(true);
        setResponses(
          await ResponseService.getResponsesBySubmiterAndSite(
            projectSelected.submitter || "",
            projectSelected.researchSite || ""
          )
        );

        setModalOpen(undefined);
        setResponseIsLoading(false);
      }
    } catch (err) {
      setResponseIsLoading(false);
    }
  };

  useEffect(() => {
    const populateQuestions = async () => {
      if (projectSelected)
        setProjectQuestions(
          await QuestionService.getQuestionsByProjectId(
            projectSelected.id || "",
            currentLanguage
          )
        );
    };

    populateResponses();
    populateQuestions();
  }, [projectSelected]);

  useEffect(() => {
    setInstructionsModal(!localStorage.getItem("alredySeeInstructions"));
  }, []);

  const onDeleteResponse = async (id?: string) => {
    if (confirmModal) {
      if (id) {
        setConfirmModal(false);
        try {
          setSubmitingResponse(true);
          await ResponseService.delete(id);
          await populateResponses();
          setSubmitingResponse(false);
        } catch (err) {
          setModalOpen(undefined);
          setSubmitingResponse(false);
        }
      }
    } else {
      setConfirmModal(id);
    }
  };

  const handleCloseInstructions = () => {
    localStorage.setItem("alredySeeInstructions", "true");
    setInstructionsModal(false);
  };

  const onSubmitResponse = async (values: any) => {
    setSubmitingResponse(true);
    try {
      if (values.id) {
        const oldResponse = responses.find((res) => res.id === values.id);
        const attachmentsToDelete =
          oldResponse?.attachments.filter(
            (attachment) =>
              !values.attachments.find(
                (newAttachment: IAttachment) =>
                  newAttachment.id === attachment.id
              )
          ) || [];

        await ResponseService.updateResponse({
          ..._.omit(values, ["continueCreating"]),
          question_identifier_id: values.question.id,
        });

        await Promise.all(
          attachmentsToDelete.map(
            async (attachment) => await AttachmentService.delete(attachment.id)
          )
        );

        await Promise.all(
          values.attachments.map(async (attachment: IAttachment | Blob) => {
            if (attachment instanceof Blob) {
              const form = new FormData();
              form.append("file", attachment);
              await AttachmentService.saveAttachment(values.id, form);
            }
          })
        );

        toast({
          title: t("response_updated_success"),
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
      } else {
        const response = await ResponseService.saveResponse({
          text: values.text,
          question_identifier_id: values.question.id,
          notes: values.notes,
          is_profund: values.is_profund,
          research_site_id: projectSelected?.researchSite,
          priority: values.priority,
          submitter: projectSelected?.submitter,
        });

        await TagService.saveTags(
          (values.responseTags as { label: string; value: string }[]).map(
            (tag) => tag.label
          ),
          response.id
        );

        await Promise.all(
          (values.attachments as Blob[]).map(async (attachment) => {
            const form = new FormData();
            form.append("file", attachment);
            await AttachmentService.saveAttachment(response.id, form);
          })
        );

        toast({
          title: t("response_created_success"),
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
      }

      populateTags();
      if (values.continueCreating) {
        console.log("aqui");
        setModalOpen({});
      } else {
        populateResponses();
        setModalOpen(undefined);
      }
      setSubmitingResponse(false);
    } catch (err) {
      toast({
        title: t("response_error"),
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
      setSubmitingResponse(false);
    }
  };

  return (
    <motion.div
      style={{ height: "100%", flex: 1 }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <VStack
        height={"100%"}
        flex={1}
        padding={"0px 18px"}
        alignItems={"flex-start"}
      >
        <HStack
          w={"100%"}
          justifyContent={"space-between"}
          alignItems={"center"}
          mt={"32px"}
        >
          <Text fontSize={"28px"} fontWeight={"600"}>
            {t("first_text_response_screen")}
          </Text>
          <StyledTooltip
            text={t("tooltip_instructions_modal")}
            onClick={() => setInstructionsModal(true)}
          />
        </HStack>
        <VStack
          flex={1}
          alignItems={"center"}
          justifyContent={"center"}
          width={"100%"}
          overflow={"hidden"}
        >
          {responseIsLoading ? (
            <InfinitySpin width="200" color="#000000" />
          ) : (
            <>
              {responses.length > 0 ? (
                <VStack
                  flex={1}
                  alignItems={"flex-start"}
                  overflow={"scroll"}
                  w={"100%"}
                >
                  {responses.map((response) => (
                    <motion.div
                      key={response.id}
                      style={{ width: "100%" }}
                      initial={{ height: 0 }}
                      animate={{ height: "fit-content" }}
                    >
                      <ResponseCard
                        response={response}
                        questionIndex={
                          (
                            (projectQuestions?.findIndex(
                              (question) =>
                                question.id === response.question_identifier_id
                            ) || 0) + 1
                          ).toString() || ""
                        }
                        onSelect={(responseSelected) =>
                          setModalOpen(responseSelected)
                        }
                        primary_collor={projectSelected?.primary_collor}
                      />
                    </motion.div>
                  ))}
                </VStack>
              ) : (
                <Text fontSize={"16px"} fontWeight={"400"}>
                  {t("null_text_response_screen")}
                </Text>
              )}
            </>
          )}

          <ResponseForm
            defaultTags={defaultTags.filter(
              (tag) => tag.project_id === projectSelected?.id
            )}
            onSubmit={onSubmitResponse}
            submitingResponse={submitingResponse}
            modalOpen={modalOpen}
            setModalOpen={(val) => {
              setModalOpen(val);
            }}
            toRemove={onDeleteResponse}
            projectQuestions={projectQuestions}
            primary_collor={projectSelected?.primary_collor}
          />
        </VStack>
        <Button
          onClick={() => {
            setModalOpen({ id: "" });
          }}
          width={"100%"}
          style={{ margin: "0px", marginBottom: "24px" }}
          background={
            projectSelected?.primary_collor
              ? projectSelected.primary_collor
              : "black"
          }
        >
          {t("add_response_button")}
        </Button>
        <Modal
          isOpen={instructionsModal}
          onClose={handleCloseInstructions}
          isCentered
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{t("instructions_modal_title")}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <p>
                {t("instructions_modal_p")}
                <strong>{t("instructions_modal_first_strong")}</strong>
                {t("instructions_modal_first_p")}
              </p>
              <p>{t("instructions_modal_second_p")}</p>
            </ModalBody>

            <ModalFooter>
              <Button
                mr={3}
                padding={"0px 16px"}
                onClick={handleCloseInstructions}
                background={
                  projectSelected?.primary_collor
                    ? projectSelected.primary_collor
                    : "#000000"
                }
              >
                Ok!
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </VStack>

      <Modal isOpen={confirmModal} onClose={() => setConfirmModal(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t("confirm_modal_title")}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{t("confirm_modal_text")}</ModalBody>

          <ModalFooter>
            <Button
              mr={3}
              onClick={() => setConfirmModal(false)}
              padding={"0px 16px"}
              background={
                projectSelected?.primary_collor
                  ? projectSelected.primary_collor
                  : "#000000"
              }
            >
              Cancel
            </Button>
            <Button
              onClick={() => onDeleteResponse(confirmModal)}
              padding={"0px 16px"}
              background={"transparent"}
              color={
                projectSelected?.primary_collor
                  ? projectSelected.primary_collor
                  : "#000000"
              }
              borderColor={
                projectSelected?.primary_collor
                  ? projectSelected.primary_collor
                  : "#000000"
              }
            >
              Continue
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </motion.div>
  );
};

export default ResponsePage;
