import {
  VStack,
  Text,
  HStack,
  Icon,
  extendTheme,
  ChakraProvider,
} from "@chakra-ui/react";
import React from "react";
import { Props } from "./types";
import { MdOutlineErrorOutline } from "react-icons/md";
import StyledTooltip from "../../../../components/base/Tooltip";
import { Switch } from "@chakra-ui/react";

const StyledSwitch: React.FC<Props> = ({
  title,
  onChange,
  value,
  error,
  primary_collor,
  tooltip_text,
}: Props) => {
  const Theme = extendTheme({
    fonts: {
      heading: `'Inter', sans-serif`,
      body: `'Inter', sans-serif`,
    },
    colors: {
      [primary_collor || "#000000"]: {
        500: primary_collor,
      },
    },
  });

  return (
    <VStack w={"100%"} alignItems={"left"} padding={"16px 0px"}>
      <HStack>
        <Text
          fontSize={"14px"}
          fontWeight={"400"}
          color={"place_holder_texts"}
          textAlign={"left"}
        >
          {title}
        </Text>
        <StyledTooltip text={tooltip_text} />
      </HStack>
      <HStack w={"100%"} spacing={"12px"}>
        <ChakraProvider theme={Theme}>
          <Switch
            isChecked={value}
            onChange={() => onChange(!value)}
            colorScheme={primary_collor || "#000000"}
            size="lg"
          />
        </ChakraProvider>
      </HStack>

      {error && (
        <HStack mt={"14px"} alignItems={"center"}>
          <Icon
            as={MdOutlineErrorOutline}
            color={"error"}
            size={"10px !important"}
            marginBottom={"2px !important"}
          />
          <Text
            fontSize={"14px"}
            fontWeight={"400"}
            color={"error"}
            textAlign={"left"}
            marginLeft={"4px !important"}
          >
            {error}
          </Text>
        </HStack>
      )}
    </VStack>
  );
};

export default StyledSwitch;
