import { VStack, Text, Icon, Stack, Box, HStack } from "@chakra-ui/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { StyledResponseButton } from "./styles";
import { IoIosArrowForward } from "react-icons/io";
import { Props } from "./types";
import { MdOutlineErrorOutline } from "react-icons/md";
import i18next from "../../../../i18n";
import { MdArrowBackIos } from "react-icons/md";
import StyledTooltip from "../../../../components/base/Tooltip";

const SelectQuestion: React.FC<Props> = (props) => {
  const { questions, onChange, value, error } = props;
  const [modal, setModal] = useState(false);
  const currentLanguage = i18next.languages[0];

  const { t } = useTranslation();
  const correctQuestions = questions.map((questionIdentifer) => ({
    ...questionIdentifer,
    content:
      questionIdentifer.content.filter(
        (question) => question.lang === currentLanguage
      ) || questionIdentifer.content.filter((question) => question.is_main),
  }));

  return (
    <VStack
      alignItems={"flex-start"}
      w={"100%"}
      borderBottom={"1px solid #F2F2F2"}
      padding={"16px 0px"}
    >
      <HStack alignItems={"center"} justifyContent={"center"}>
        <Text fontSize={"14px"} fontWeight={"400"} color={"place_holder_texts"}>
          {t("first_text_modal_response_screen")}
        </Text>
        <StyledTooltip text={t("question_tooltip")} />
      </HStack>

      <StyledResponseButton onClick={() => setModal(true)}>
        <Text
          fontSize={"18px"}
          fontWeight={500}
          color={"black"}
          textAlign={"left"}
        >
          {value
            ? (
                value.content.find(
                  (question) => question.lang === currentLanguage
                ) || value.content.find((question) => question.is_main)
              )?.title
            : t("question_text_modal_response_screen")}
        </Text>
        <Icon as={IoIosArrowForward} size={"12px !important"} />
      </StyledResponseButton>

      <Stack
        zIndex={2}
        position={"absolute"}
        width={"100%"}
        height={modal ? "100%" : "0"}
        background={"rgba(0, 0, 0, 0.75)"}
        left={0}
        top={0}
        margin={"0px !important"}
      />
      <VStack
        zIndex={3}
        position={"absolute"}
        bottom={0}
        left={0}
        transition={"500ms all"}
        width={"100%"}
        maxHeight={modal ? "100%" : "0px"}
        overflow={"hidden"}
        background={"white"}
        borderRadius={"16px 16px 0px 0px"}
      >
        <VStack padding={"32px 16px"} alignItems={"flex-start"} w={"100%"}>
          {correctQuestions.length > 0 && (
            <Text
              fontSize={"24px"}
              fontWeight={600}
              color={"black"}
              mb={"16px"}
              textAlign={"left"}
            >
              {t("question_text_modal_response_screen")}
            </Text>
          )}

          {correctQuestions.length <= 0 ? (
            <Box height={"80px"} width={"100%"}>
              <HStack
                width={"100%"}
                height={"100%"}
                alignItems={"center"}
                justifyContent={"center"}
                display={"flex"}
                cursor={"pointer"}
                onClick={() => setModal(false)}
              >
                <Icon
                  as={MdArrowBackIos}
                  size={"12px !important"}
                  color={
                    props.primary_collor ? props.primary_collor : "#000000"
                  }
                />
                <Text
                  fontSize={"14px"}
                  fontWeight={500}
                  color={"black"}
                  textAlign={"left"}
                >
                  {t("questions_not_found")}
                </Text>
              </HStack>
            </Box>
          ) : (
            <>
              {correctQuestions.map(
                (questionIdentifier: any, index: number) => (
                  <Box
                    borderBottom={"1px solid #F2F2F2"}
                    width={"100%"}
                    key={index}
                  >
                    <StyledResponseButton
                      onClick={() => {
                        setModal(false);
                        onChange && onChange(questionIdentifier);
                      }}
                    >
                      <Text
                        fontSize={"14px"}
                        fontWeight={400}
                        color={"black"}
                        textAlign={"left"}
                      >
                        {questionIdentifier.content[0]?.title}
                      </Text>
                      <Icon as={IoIosArrowForward} h={"12px !important"} />
                    </StyledResponseButton>
                  </Box>
                )
              )}
            </>
          )}
        </VStack>
      </VStack>

      {error && (
        <HStack mt={"14px"} alignItems={"center"}>
          <Icon
            as={MdOutlineErrorOutline}
            color={"error"}
            size={"10px !important"}
            marginBottom={"2px !important"}
          />
          <Text
            fontSize={"14px"}
            fontWeight={"400"}
            color={"error"}
            textAlign={"left"}
            marginLeft={"4px !important"}
          >
            {error}
          </Text>
        </HStack>
      )}
    </VStack>
  );
};

export default SelectQuestion;
