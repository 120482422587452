import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`

* {
    box-sizing: border-box;
}

body {
    margin: 0;
    border: 0;
    padding: 0;
}

body,
html,
#root {
    height: 100%;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 8px;
  padding: 1px;
}

.r-borderColor-llkbrl {
  border-color: black !important;
}

`;
