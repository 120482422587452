import { HStack } from "@chakra-ui/react";
import React from "react";
import LanguageSelect from "../LanguageSelect";

const LanguageContainer: React.FC = () => {
  return (
    <HStack
      width={"100%"}
      padding={"18px 24px"}
      alignItems={"center"}
      justifyContent={"end"}
      borderBottom={"1px solid #F5F5F5"}
    >
      <LanguageSelect />
    </HStack>
  );
};

export default LanguageContainer;
