import { Text, VStack } from "@chakra-ui/react";
import React from "react";

const NotFoundPage: React.FC = () => {
  return (
    <VStack height={"100%"} alignItems={"center"} justifyContent={"center"}>
      <Text fontSize={"28px"} fontWeight={600} textAlign={"center"}>
        Oops,
      </Text>
      <Text fontSize={"24px"} fontWeight={400} textAlign={"center"}>
        This page cannot be found.
      </Text>
    </VStack>
  );
};

export default NotFoundPage;
