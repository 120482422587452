import styled from "styled-components";
import { background, color, layout, margin, space } from "styled-system";

export const StyledButton = styled.button`
  background-color: #000000;
  ${background}
  ${margin}
  ${color}
  ${space}
  ${layout}

  color: ${(props) => (props.color ? props.color : "white")};
  border: 1px solid;

  align-items: center;
  justify-content: center;
  display: flex;
  font-family: "Inter", sans-serif;
  text-align: center;
  font-weight: 500;
  border-radius: 500px;
  height: 40px;
  transition: 300ms all;

  &:hover {
    filter: contrast(0.8);
  }

  &:disabled {
    background-color: #4d4d4d;
    cursor: not-allowed;
  }
`;
