import { Box, Center, Text, VStack } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { InfinitySpin } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { Input } from "../../components/base/Input";
import { SelectWModal } from "../../components/base/Select";
import LanguageContainer from "../../components/LanguageContainer";
import { ProjectContext } from "../../contexts/project-context";
import ProjectService from "../../services/project.service";
import { IProject, IResearchSite } from "../../types";
import { motion } from "framer-motion";
import Button from "../../components/base/Button";

const SelectProjectPage: React.FC = () => {
  const { t } = useTranslation();
  const [projectsAvaible, setProjectsAvaible] = useState<
    IProject[] | undefined
  >();
  const [sitesAvaible, setSitesAvaible] = useState<IResearchSite[] | []>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { setProject, projectSelected, recaptchaResolved } =
    useContext(ProjectContext);
  const { control, handleSubmit, getValues, watch, setValue } = useForm({
    defaultValues: {
      submitter: projectSelected?.submitter || undefined,
      project: projectSelected
        ? { id: projectSelected?.id, value: projectSelected?.name }
        : undefined,
      site: projectSelected
        ? {
            id: projectSelected.researchSite,
            value: projectSelected.researchSites?.find(
              (item) => item.id === projectSelected.researchSite
            )?.name,
          }
        : undefined,
    },
  });
  const watched = watch("project");
  const navigate = useNavigate();

  useEffect(() => {
    if (recaptchaResolved) {
      if (!projectsAvaible) {
        const populateProjects = async () => {
          try {
            const allProjects = await ProjectService.getAllProjects();
            setProjectsAvaible(allProjects);
            setIsLoading(false);
          } catch (err) {
            setIsLoading(false);
          }
        };

        populateProjects();
      }
    } else {
      navigate("/", { replace: true });
    }
  });

  const handleFormSubmit = (values: any) => {
    const selectedProject = projectsAvaible?.find(
      (project) => project.id === values.project.id
    );
    if (selectedProject) {
      setProject({
        ...selectedProject,
        id: values.project.id,
        researchSite: values.site.id,
        submitter: values.submitter,
      });
    }
  };

  useEffect(() => {
    if (projectsAvaible || projectSelected) {
      const project = projectsAvaible?.find(
        (project) => project.id === getValues()?.project?.id
      );
      setSitesAvaible(
        project?.researchSites || projectSelected?.researchSites || []
      );

      if (project) {
        setProject({ primary_collor: project?.primary_collor });
        setValue("site", /* { id: "0000", value: "22222" } */ undefined);
      }
    }
  }, [watched]);

  return (
    <motion.div
      style={{ height: "100%", flex: 1 }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <VStack height={"100%"}>
        <LanguageContainer />
        <VStack flex={1} width={"100%"} padding={"0px 18px"} overflow={"auto"}>
          <VStack flex={1} alignItems={"flex-start"}>
            <Text mt={"24px"} fontWeight={"600"} fontSize={"24px"}>
              {t("select_project_first_text")}
            </Text>
            <Text mt={"16px"} fontWeight={"400"} fontSize={"16px"}>
              {t("select_project_second_text")}
            </Text>
            <VStack
              width={"100%"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              {isLoading ? (
                <Box mt={"32px"}>
                  <InfinitySpin width="200" color="#000000" />
                </Box>
              ) : (
                <>
                  {projectsAvaible ? (
                    <VStack width={"100%"}>
                      <Controller
                        name={"submitter"}
                        control={control}
                        rules={{ required: true }}
                        render={({
                          field: { onBlur, onChange, value },
                          formState: { errors },
                        }) => (
                          <Input
                            placeholder={t("select_project_input")}
                            hasError={!!errors.submitter}
                            errorMessage={t("required_field_error")}
                            value={value || ""}
                            onChange={onChange}
                            onBlur={onBlur}
                            mt={"24px !important"}
                          />
                        )}
                      />

                      <Controller
                        name={"project"}
                        control={control}
                        rules={{ required: true }}
                        render={({
                          field: { onChange, value },
                          formState: { errors },
                        }) => (
                          <SelectWModal
                            itemSelected={value}
                            mt={"24px !important"}
                            items={projectsAvaible}
                            SelectItem={(item) => {
                              onChange(item);
                            }}
                            hasError={!!errors.project}
                            placeholder={t("select_project_first_select")}
                            convertItem={(item: IProject) => ({
                              id: item?.id,
                              value: item?.name,
                            })}
                          />
                        )}
                      />

                      <Controller
                        name={"site"}
                        control={control}
                        rules={{ required: true }}
                        render={({
                          field: { onChange, value },
                          formState: { errors },
                        }) => (
                          <SelectWModal
                            itemSelected={getValues().site}
                            mt={"24px !important"}
                            items={sitesAvaible}
                            SelectItem={(item) => {
                              onChange(item);
                            }}
                            hasError={!!errors.site}
                            placeholder={t("select_project_second_select")}
                            convertItem={(item: IResearchSite) => ({
                              id: item?.id,
                              value: item?.name,
                            })}
                          />
                        )}
                      />
                    </VStack>
                  ) : (
                    <Center h={"100%"} w={"100%"}>
                      <Text fontWeight={"600"} fontSize={"24px"}>
                        {t("select_project_not_found_projects_text")}
                      </Text>
                    </Center>
                  )}
                </>
              )}
            </VStack>
          </VStack>
          <Button
            onClick={handleSubmit(handleFormSubmit)}
            width={"100%"}
            minHeight={"40px !important"}
            style={{ margin: "0px", marginBottom: "24px", marginTop: "16px" }}
            background={
              projectSelected?.primary_collor
                ? projectSelected.primary_collor
                : "black"
            }
          >
            {t("select_project_button")}
          </Button>
        </VStack>
      </VStack>
    </motion.div>
  );
};

export default SelectProjectPage;
