import { VStack, Text, Center, Box } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { InfinitySpin } from "react-loader-spinner";
import { useNavigate, useParams } from "react-router-dom";
import { Input } from "../../components/base/Input";
import LanguageContainer from "../../components/LanguageContainer";
import { ProjectContext } from "../../contexts/project-context";
import ResearchSiteService from "../../services/site.service";
import { IProject } from "../../types";
import { motion } from "framer-motion";
import Button from "../../components/base/Button";
import i18next from "../../i18n";

const JoinByLinkPage: React.FC = () => {
  const { t } = useTranslation();
  const { site } = useParams();
  const navigate = useNavigate();
  const [newProject, setNewProject] = useState<IProject | undefined>();
  const [isLoading, setIsLoading] = useState(true);
  const { setProject, projectSelected } = useContext(ProjectContext);
  const { control, handleSubmit } = useForm({
    defaultValues: {
      submitter: projectSelected?.submitter || "",
    },
  });

  useEffect(() => {
    const populateNewProject = async () => {
      try {
        const res = await ResearchSiteService.getResearchSite(site || "");
        i18next.changeLanguage(res?.lang);
        console.log(res.lang);
        setNewProject({ ...res.project, researchSite: res.id });
        if (!projectSelected) {
          setProject({ primary_collor: res.project.primary_collor });
        }
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
      }
    };

    populateNewProject();
  }, [site]);

  const onSubmit = (values: any) => {
    if (newProject) {
      setProject({ ...newProject, submitter: values.submitter });
    }
  };

  return (
    <motion.div
      style={{ height: "100%", flex: 1 }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <VStack
        height={"100%"}
        flex={1}
        padding={"0px 18px"}
        alignItems={"flex-start"}
      >
        <LanguageContainer />

        {isLoading ? (
          <Center
            flex={1}
            alignItems={"center"}
            justifyContent={"center"}
            width={"100%"}
          >
            <InfinitySpin width="200" color="#000000" />
          </Center>
        ) : (
          <>
            {newProject ? (
              <>
                {!newProject.is_active ? (
                  <VStack
                    flex={1}
                    alignItems={"center"}
                    justifyContent={"center"}
                    width={"100%"}
                  >
                    <Text fontSize={"24px"} fontWeight={"600"}>
                      Oops!
                    </Text>
                    <Text fontSize={"16px"} fontWeight={"400"}>
                      {t("project_inactive_join_screen")}
                    </Text>
                  </VStack>
                ) : (
                  <VStack
                    flex={1}
                    alignItems={"center"}
                    justifyContent={"center"}
                    width={"100%"}
                  >
                    <Text
                      fontSize={"20px"}
                      fontWeight={"400"}
                      textAlign={"center"}
                    >
                      {newProject.short_name}
                    </Text>
                    <Text
                      fontSize={"20px"}
                      fontWeight={"600"}
                      textAlign={"center"}
                    >
                      {t("select_project_first_text")}
                    </Text>

                    <Controller
                      name={"submitter"}
                      control={control}
                      rules={{ required: true }}
                      render={({
                        field: { onBlur, onChange, value },
                        formState: { errors },
                      }) => (
                        <Input
                          placeholder={t("select_project_input")}
                          hasError={!!errors.submitter}
                          errorMessage={t("required_field_error")}
                          value={value || ""}
                          onChange={onChange}
                          onBlur={onBlur}
                          mt={"24px !important"}
                          mb={"24px !important"}
                        />
                      )}
                    />
                  </VStack>
                )}
              </>
            ) : (
              <VStack
                flex={1}
                alignItems={"center"}
                justifyContent={"center"}
                width={"100%"}
              >
                <Text fontSize={"24px"} fontWeight={"600"}>
                  Oops!
                </Text>
                <Text fontSize={"16px"} fontWeight={"400"}>
                  {t("not_found_join_screen")}
                </Text>
              </VStack>
            )}
            {!newProject || !newProject?.is_active ? (
              <Button
                onClick={() => navigate("/")}
                width={"100%"}
                style={{ margin: "0px", marginBottom: "24px" }}
              >
                {t("select_project_error_button")}
              </Button>
            ) : (
              <>
                <Box mb={"8px !important"} w={"100%"} textAlign={"center"}>
                  <Text
                    fontSize={"14px"}
                    onClick={() => {}}
                    display={"inline-block"}
                  >
                    {t("privacy_policy_pre_text")}
                  </Text>
                  <Text
                    fontSize={"14px"}
                    fontWeight={"500"}
                    textDecoration={"underline"}
                    onClick={() => {
                      window.open("/Knowmad_Cafe_GDPR.html", "_blank");
                    }}
                    cursor={"pointer"}
                    marginLeft={"5px !important"}
                    display={"inline-block"}
                  >
                    {t("privacy_policy")}
                  </Text>
                </Box>

                <Button
                  onClick={handleSubmit(onSubmit)}
                  width={"100%"}
                  style={{ margin: "0px", marginBottom: "24px" }}
                  background={
                    projectSelected?.primary_collor
                      ? projectSelected.primary_collor
                      : "#000000"
                  }
                >
                  {t("select_project_button")}
                </Button>
              </>
            )}
          </>
        )}
      </VStack>
    </motion.div>
  );
};

export default JoinByLinkPage;
