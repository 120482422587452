import styled from "styled-components";

export const StyledCheckButton = styled.button<{
  isCheked?: boolean;
  primary_collor?: string;
}>`
  transition: 300ms all;
  border-radius: 500px;
  padding: 12px 16px;

  background-color: ${(props) =>
    props.isCheked ? props.primary_collor : "transparent"};
  border: 1px solid ${(props) => props.primary_collor};
  color: ${(props) => (props.isCheked ? "#FFFFFF" : props.primary_collor)};
`;
