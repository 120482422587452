import { Box, Center } from "@chakra-ui/react";
import React from "react";
import useDeviceDetection from "../../hooks/IsMobile";
import { Props } from "./types";

const ApplicationWrapper: React.FC<Props> = (props: Props) => {
  const { isMobile } = useDeviceDetection();

  return (
    <>
      {isMobile ? (
        props.children
      ) : (
        <Center height={"100%"} background={"#FAFAFA"}>
          <Box
            w={"390px"}
            h={"90%"}
            background={"#FFFFFF"}
            borderRadius={"16px"}
            boxShadow="0px 0px 10px rgba(29, 34, 31, 0.1)"
          >
            {props.children}
          </Box>
        </Center>
      )}
    </>
  );
};

export default ApplicationWrapper;
