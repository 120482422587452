import React from "react";
import { Props } from "./types";
import CreatableSelect from "react-select/creatable";
import { VStack, Text, HStack } from "@chakra-ui/react";
import StyledTooltip from "../../../../components/base/Tooltip";
import { useTranslation } from "react-i18next";

const TagSelect: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();

  const customStyles = {
    option: (provided: any, state: any) => ({
      ...provided,

      background: state.isFocused
        ? props.primary_collor
          ? props.primary_collor
          : "#000000"
        : "transparent",
      color: state.isFocused
        ? "#ffffff"
        : props.primary_collor
        ? props.primary_collor
        : "#000000",
    }),
    control: (provided: any, state: any) => ({
      ...provided,
      borderColor: "#000000 !important",
      borderWidth: "#000000 !important",
      boxShadow: "#000000 !important",
    }),
  };

  return (
    <VStack
      w={"100%"}
      borderBottom={"1px solid #F2F2F2"}
      alignItems={"left"}
      padding={"16px 0px"}
    >
      <HStack>
        <Text
          fontSize={"14px"}
          fontWeight={"400"}
          color={"place_holder_texts"}
          textAlign={"left"}
        >
          {props.title}
        </Text>
        <StyledTooltip text={t("tag_tooltip")} />
      </HStack>
      <CreatableSelect {...props} value={props.value} styles={customStyles} />
    </VStack>
  );
};

export default TagSelect;
