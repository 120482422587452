import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  fonts: {
    heading: `'Inter', sans-serif`,
    body: `'Inter', sans-serif`,
  },
  semanticTokens: {
    colors: {
      error: "red.500",
      success: "green.500",
      black: "#000000",
      button_solid_hover: "#121212",
      place_holder_texts: "#4D4D4D",
      white: "#FFFFFF",
    },
  },
  components: {
    Button: {
      baseStyle: {
        borderRadius: "500px",
      },

      variants: {
        solid: {
          bg: "black",
          color: "white",
          _hover: {
            bg: "button_solid_hover",
          },
        },
        outline: {
          bg: "transparent",
          color: "black",
          borderColor: "black",
        },
      },
    },
  },
});

export default theme;
