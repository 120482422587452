import { VStack, Text, HStack, Icon } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { StyledInsertImageButton } from "./styles";
import { Props } from "./types";
import { BsTrash } from "react-icons/bs";
import { BsImage } from "react-icons/bs";
import { motion } from "framer-motion";
import StyledTooltip from "../../../../components/base/Tooltip";

const ImagePicker: React.FC<Props> = ({
  value,
  onInsertImage,
  onDeleteImage,
}: Props) => {
  const { t } = useTranslation();

  const defaultTransitionVariant = {
    visible: { opacity: 1, scale: 1 },
    hidden: { opacity: 0, scale: 0 },
  };

  const handleClick = () => {
    const inputfile = document.getElementById("inputphoto") as HTMLInputElement;
    inputfile.click();
  };

  const handleChange = (e: any) => {
    const file = e.target.files[0];
    onInsertImage(file);
  };

  const bytesToMegaBytes = (bytes: number) => bytes / 1024 ** 2;

  return (
    <VStack
      w={"100%"}
      borderBottom={"1px solid #F2F2F2"}
      alignItems={"left"}
      padding={"16px 0px"}
    >
      <HStack>
        <Text
          fontSize={"14px"}
          fontWeight={"400"}
          color={"place_holder_texts"}
          textAlign={"left"}
        >
          {t("image_picker_text_modal_response_screen")}
        </Text>
        <StyledTooltip text={t("attachments_tooltip")} />
      </HStack>

      {value.length > 0 && (
        <>
          {value.map((val, index) => (
            <motion.div
              key={index}
              initial="hidden"
              animate="visible"
              exit={"hidden"}
              variants={defaultTransitionVariant}
              style={{
                width: "100%",
              }}
            >
              <HStack
                padding={"16px 18px"}
                alignItems={"center"}
                width={"100%"}
                border={"1px solid rgba(0, 0, 0, 0.15)"}
                justifyContent={"space-between"}
                borderRadius={"12px"}
              >
                <HStack>
                  <Icon as={BsImage} w={"20px !important"} />
                  <VStack ml={"10px"} alignItems={"flex-start"}>
                    <Text fontSize={"14px"} fontWeight={"500"} color={"black"}>
                      {val?.name}
                    </Text>

                    <Text
                      fontSize={"14px"}
                      fontWeight={"400"}
                      color={"place_holder_texts"}
                    >
                      {`${bytesToMegaBytes(val?.size)
                        .toFixed(2)
                        .toString()} MB • ${(val?.type as string)
                        .split("/")[1]
                        .toUpperCase()}`}
                    </Text>
                  </VStack>
                </HStack>

                <Icon
                  as={BsTrash}
                  w={"20px !important"}
                  cursor={"pointer"}
                  onClick={() => onDeleteImage(val)}
                />
              </HStack>
            </motion.div>
          ))}
        </>
      )}

      <StyledInsertImageButton onClick={handleClick}>
        <Text fontSize={"16px"} fontWeight={"500"} color={"black"}>
          {t("image_picker_button_text_modal_response_screen")}
        </Text>
      </StyledInsertImageButton>

      <input
        onChange={handleChange}
        id="inputphoto"
        style={{ display: "none" }}
        type="file"
        name="file"
        accept="image/*"
      />
    </VStack>
  );
};

export default ImagePicker;
