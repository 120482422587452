import React, { useContext } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import HomePage from "../pages/Home";
import JoinByLinkPage from "../pages/JoinByLink";
import NotFoundPage from "../pages/NotFound";
import ResponsePage from "../pages/Response";
import SelectProjectPage from "../pages/SelectProject";
import { AnimatePresence } from "framer-motion";
import Header from "../components/Header";
import { Box } from "@chakra-ui/react";
import { ProjectContext } from "../contexts/project-context";

const Router = () => {
  const { projectSelected } = useContext(ProjectContext);
  const location = useLocation();
  return (
    <>
      <Header primary_collor={projectSelected?.primary_collor} />
      <Box flex={1} height={"calc(100% - 56px)"} overflow={"hidden"}>
        <AnimatePresence>
          <Routes location={location} key={location.pathname}>
            <Route path="/" element={<HomePage />} />
            <Route path="/selectproject" element={<SelectProjectPage />} />
            <Route path="/project/:shortname" element={<ResponsePage />} />
            <Route path="/:site" element={<JoinByLinkPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </AnimatePresence>
      </Box>
    </>
  );
};

export default Router;
