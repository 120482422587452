import { Box, Text, VStack } from "@chakra-ui/react";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import LanguageContainer from "../../components/LanguageContainer";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from "react-router-dom";
import { ProjectContext } from "../../contexts/project-context";
import { motion } from "framer-motion";
import Button from "../../components/base/Button";

const HomePage: React.FC = () => {
  const { recaptchaResolved, setRecaptchaResolved } =
    useContext(ProjectContext);
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <motion.div
      style={{ height: "100%", flex: 1 }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <VStack height={"100%"}>
        <LanguageContainer />
        <VStack flex={1} width={"100%"} padding={"0px 18px"}>
          <VStack flex={1} alignItems={"center"} justifyContent={"center"}>
            <Text
              fontWeight={600}
              fontSize={"20px"}
              color={"black"}
              textAlign={"center"}
              mb={"24px"}
            >
              {t("home_text")}
            </Text>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ""}
              onChange={(event) => setRecaptchaResolved(event || undefined)}
            />
          </VStack>

          <Box mb={"8px !important"} w={"100%"} textAlign={"center"}>
            <Text fontSize={"14px"} onClick={() => {}} display={"inline-block"}>
              {t("privacy_policy_pre_text")}
            </Text>
            <Text
              fontSize={"14px"}
              fontWeight={"500"}
              textDecoration={"underline"}
              onClick={() => {
                window.open("/Knowmad_Cafe_GDPR.html", "_blank");
              }}
              cursor={"pointer"}
              marginLeft={"5px !important"}
              display={"inline-block"}
            >
              {t("privacy_policy")}
            </Text>
          </Box>
          <Button
            onClick={() => {
              navigate("/selectproject", { replace: true });
            }}
            width={"100%"}
            style={{ margin: "0px", marginBottom: "24px" }}
            disabled={!recaptchaResolved}
          >
            {t("home_button_text")}
          </Button>
        </VStack>
      </VStack>
    </motion.div>
  );
};

export default HomePage;
