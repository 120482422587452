const enTranslation = {
  show_all_fields: "Show all fields",
  response_modal_add_another: "Save and add next",
  home_button_text: "Continue",
  home_text: "Before we get started, please certify you are not a robot.",
  select_project_first_text: "Thank you for participating in the Knowmad Café!",
  select_project_second_text:
    "Please select your project and meeting location.",
  select_project_button: "Continue",
  select_project_input: "Your e-mail address",
  required_field_error: "This field is required.",
  select_project_first_select: "Project",
  select_project_second_select: "Location",
  default_placeholder_site: "Select your location",
  first_text_response_screen: "My group's responses",
  null_text_response_screen: "No responses recorded, yet!",
  add_response_button: "Add new",
  not_found_join_screen: "This project cannot be found.",
  project_inactive_join_screen: "This project is no longer active.",
  first_text_join_screen: "Project data",
  response_modal_first_test: "Response entry",
  response_modal_save_button: "Save",
  select_project_not_found_projects_text: "No projects found",
  select_project_error_button: "Back to home",
  first_text_modal_response_screen: "Question",
  question_text_modal_response_screen: "Select a question",
  response_text_modal_response_screen: "Group response",
  placeholder_response:
    "Write your group's response to the selected question here...",
  priority_text_modal_response_screen: "Response priority",
  is_profund_text_modal_response_screen: "Profound?",
  notes_text_modal_response_screen: "Notes and comments (optional)",
  placeholder_notes: "Notes and comments",
  image_picker_text_modal_response_screen: "Upload an image (optional)",
  image_picker_button_text_modal_response_screen: "Select file",
  saving_response: "Just a moment. Your response is being saved.",
  tag_text_modal_response_screen: "Tags",
  tag_placeholder_modal_response_screen: "Add a tag",
  response_created_success: "Your response was successfully stored.",
  response_updated_success: "Your response was successfully updated.",
  response_error: "There is an error in a field.",
  questions_not_found:
    "The admin has not yet registered questions for this event, please try again later.",

  //Commom texts
  back_text: "back",
  yes: "Yes",
  no: "No",
  low: "Low",
  medium: "Medium",
  high: "High",
  lets_start: "Let's start!",

  //Tooltips
  question_tooltip: "Select the question connected with this response.",
  response_tooltip:
    "Write your group’s response to the question here. If your group has more than one idea or response to the question, please enter each separately.",
  priority_tooltip: "Indicate how important this response was for the group.",
  is_deep_tooltip:
    "If you feel this response provides great insight, indicate it here.",
  notes_tooltip:
    "Use this space to include any additional notes, comments, etc., related to this response.",
  tag_tooltip: "Enter a few tags to help categorize this response.",
  attachments_tooltip:
    "You can upload photos in this field that help illustrate the group’s response.",
  tooltip_instructions_modal: "Click to view the instructions",

  //Instructions_modal
  instructions_modal_title: "Getting started",
  instructions_modal_p: "For ",
  instructions_modal_first_strong: "each idea ",
  instructions_modal_first_p:
    "presented by your table group, click on “add new to document your responses. You may post as many responses as your group desires.",
  instructions_modal_second_p:
    "Please make sure to rate the priority of the response, and, if an idea strikes the group as truly profound, deserving extra attention, please indicate so.",

  //Privacy policy button
  privacy_policy_pre_text: "By clicking continue you agree to our",
  privacy_policy: "privacy policy",

  //Modal delete confirmation
  confirm_modal_title: "Confirm deletion",
  confirm_modal_text:
    "Do you really want to delete this response? This action cannot be undone.",

  //Modal close confirmation
  confirm_modal_close_title: "Confirm exit",
  confirm_modal_close_text:
    "Your response is not saved yet, do you really want to close?",
};

export default enTranslation;
