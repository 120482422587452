import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TagService from "../services/tag.service";
import { ITag, IProject } from "../types";

type AuthProps = {
  defaultTags: ITag[];
  projectSelected?: Partial<IProject>;
  setProject: (project: Partial<IProject>) => void;
  recaptchaResolved: string | undefined;
  setRecaptchaResolved: (code: string | undefined) => void;
  populateTags: () => void;
};

export const ProjectContext = React.createContext<AuthProps>({
  defaultTags: [],
  projectSelected: undefined,
  setProject: () => {},
  recaptchaResolved: undefined,
  setRecaptchaResolved: () => {},
  populateTags: () => {},
});

const ProjectContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const navigate = useNavigate();
  const [projectSelected, setProjectSelected] = useState<Partial<IProject>>();
  const [defaultTags, setDefaultTags] = useState<ITag[]>([]);
  const [recaptchaResolved, setRecaptchaResolved] = useState<
    string | undefined
  >();

  const setProject = (project: Partial<IProject>) => {
    setProjectSelected(project);
    if (project.id) {
      navigate(`/project/${project?.short_name}`);
    }
  };

  const populateTags = async () => {
    setDefaultTags(await TagService.getDefaultTags());
  };

  useEffect(() => {
    if (!defaultTags || defaultTags.length === 0) {
      populateTags();
    }
  }, []);

  return (
    <ProjectContext.Provider
      value={{
        populateTags,
        recaptchaResolved,
        setRecaptchaResolved,
        projectSelected,
        setProject,
        defaultTags,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};

export default ProjectContextProvider;
