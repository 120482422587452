import {
  VStack,
  Center,
  Text,
  HStack,
  Modal as ChakraModal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React, { useEffect, useState, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { InfinitySpin } from "react-loader-spinner";
import useDeviceDetection from "../../../hooks/IsMobile";
import { IQuestionIdentifier, IResponse, ITag } from "../../../types";
import ImagePicker from "./ImagePicker";
import InputResponse from "./InputResponse";
import MultipleCheckbox from "./MultipleCheckbox";
import { Modal } from "./ResponseModal";
import SelectQuestion from "./SelectQuestion";
import { motion } from "framer-motion";

import TagSelect from "./TagSelect";
import StyledSwitch from "./Switch";
import Button from "../../../components/base/Button";

function capitalizeFirstLetter(string: string) {
  return (string.charAt(0).toUpperCase() + string.slice(1)).trim();
}

interface Props {
  modalOpen: Partial<IResponse> | undefined;
  setModalOpen: (val: Partial<IResponse> | undefined) => void;
  projectQuestions?: IQuestionIdentifier[];
  onSubmit: (values: any) => {};
  toRemove: (id?: string) => {};
  submitingResponse: boolean;
  defaultTags: ITag[];
  primary_collor?: string;
}

const ResponseForm: React.FC<Props> = ({
  modalOpen,
  onSubmit,
  projectQuestions,
  setModalOpen,
  submitingResponse,
  defaultTags,
  toRemove,
  primary_collor,
}: Props) => {
  const [showAllFields, setShowAllFields] = useState(false);
  const [confirmClose, setConfirmClose] = useState(false);
  const { isMobile } = useDeviceDetection();
  const inputRef = useRef<any>(null);
  const {
    control,
    handleSubmit,
    reset,
    formState: { touchedFields },
  } = useForm({
    defaultValues: {
      id: "",
      question: undefined,
      text: "",
      priority: "Medium",
      is_profund: false,
      notes: "",
      attachments: [],
      responseTags: [{ value: "", label: "", isOld: true }],
    },
  });

  useEffect(() => {
    reset({
      id: modalOpen?.id || undefined,
      question: modalOpen?.question_identifier || (undefined as any),
      text: modalOpen?.text || "",
      priority: modalOpen?.priority || "",
      is_profund: modalOpen?.is_profund || false,
      notes: modalOpen?.notes || "",
      attachments: (modalOpen?.attachments as any) || [],
      responseTags:
        modalOpen?.responseTags?.map((responseTag) => ({
          value: responseTag.id,
          label: responseTag.tag.name,
          isOld: true,
        })) || [],
    });
  }, [modalOpen, reset]);

  const { t } = useTranslation();

  const defaultTransitionVariant = {
    visible: { opacity: 1, scale: 1 },
    hidden: { opacity: 0, scale: 0 },
  };

  const handleCloseModal = () => {
    if (confirmClose || Object.keys(touchedFields).length === 0) {
      setConfirmClose(false);
      setShowAllFields(false);
      setModalOpen(undefined);
    } else {
      setConfirmClose(true);
    }
  };

  return (
    <Modal
      isOpen={!!modalOpen}
      onClose={() => handleCloseModal()}
      save={handleSubmit((val) => {
        onSubmit({ ...val, continueCreating: false });
        setShowAllFields(false);
      })}
      toRemove={modalOpen?.id ? () => toRemove(modalOpen?.id) : undefined}
      onContinue={
        !modalOpen?.id
          ? handleSubmit((val) => {
              onSubmit({ ...val, continueCreating: true });
              setShowAllFields(false);
            })
          : undefined
      }
      primary_collor={primary_collor}
    >
      {projectQuestions ? (
        <VStack
          alignItems={"flex-start"}
          height={`calc(${isMobile ? "100%" : "90vh"} - 89px)`}
          overflow={"hidden"}
        >
          <motion.div
            initial="hidden"
            animate="visible"
            variants={defaultTransitionVariant}
            style={{
              flex: 1,
              width: "100%",
              height: "100%",
              overflow: "auto",
              padding: "0px 16px 16px 16px",
            }}
          >
            <Center
              position={"absolute"}
              zIndex={"3"}
              left={0}
              bottom={0}
              maxH={submitingResponse ? "100%" : "0"}
              transition={"600ms all"}
              background={"white"}
              height={"100%"}
              overflow={"hidden"}
              width={"100%"}
            >
              <VStack>
                <InfinitySpin width="200" color="#000000" />
                <Text fontSize={"18px"} fontWeight={"400"}>
                  {t("saving_response")}
                </Text>
              </VStack>
            </Center>

            <Controller
              name={"question"}
              control={control}
              rules={{ required: t("required_field_error") }}
              render={({
                field: { onChange, value },
                formState: { errors },
              }) => (
                <SelectQuestion
                  questions={projectQuestions}
                  onChange={(question) => onChange(question)}
                  value={value}
                  error={errors.question?.message as any}
                  primary_collor={primary_collor}
                />
              )}
            />

            <Controller
              name={"text"}
              control={control}
              rules={{ required: t("required_field_error") }}
              render={({
                field: { onChange, onBlur, ref, value },
                formState: { errors },
              }) => (
                <InputResponse
                  title={t("response_text_modal_response_screen")}
                  placeholder={t("placeholder_response")}
                  error={errors.text?.message}
                  tooltip_text={t("response_tooltip")}
                  onBlur={onBlur}
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    if (inputRef.current) {
                      inputRef.current.style.height = "72px";
                      inputRef.current.style.height =
                        inputRef?.current?.scrollHeight + "px";
                    }
                  }}
                  ref={inputRef}
                />
              )}
            />

            <Controller
              name={"responseTags"}
              control={control}
              render={({
                field: { onChange, value },
                formState: { errors },
              }) => (
                <TagSelect
                  title={t("tag_text_modal_response_screen")}
                  isMulti
                  options={defaultTags.map((val) => ({
                    label: val.name,
                    value: val.name,
                  }))}
                  placeholder={t("tag_placeholder_modal_response_screen")}
                  value={value}
                  onChange={(event) => {
                    onChange(
                      event.map(
                        (tag: {
                          label: string;
                          value: string;
                          isOld: true;
                        }) => ({
                          label: capitalizeFirstLetter(tag.label),
                          value: tag.value,
                          isOld: tag.isOld,
                        })
                      )
                    );
                  }}
                  primary_collor={primary_collor}
                />
              )}
            />

            <HStack borderBottom={"1px solid #F2F2F2"} alignItems={"start"}>
              <Controller
                name={"priority"}
                control={control}
                rules={{ required: t("required_field_error") }}
                render={({
                  field: { value, onChange },
                  formState: { errors },
                }) => (
                  <MultipleCheckbox
                    options={[
                      { label: t("low"), value: "Low" },
                      { label: t("medium"), value: "Medium" },
                      { label: t("high"), value: "High" },
                    ]}
                    title={t("priority_text_modal_response_screen")}
                    value={value}
                    onChange={(val) => onChange(val)}
                    error={errors.priority?.message}
                    primary_collor={primary_collor}
                    tooltip_text={t("priority_tooltip")}
                  />
                )}
              />

              <Controller
                name={"is_profund"}
                control={control}
                rules={{ required: false }}
                render={({
                  field: { value, onChange },
                  formState: { errors },
                }) => (
                  <StyledSwitch
                    title={t("is_profund_text_modal_response_screen")}
                    value={Boolean(value)}
                    onChange={onChange}
                    error={errors.is_profund?.message}
                    primary_collor={primary_collor}
                    tooltip_text={t("is_deep_tooltip")}
                  />
                )}
              />
            </HStack>

            {!showAllFields && (
              <Text
                mt={6}
                fontSize={"16px"}
                fontWeight={400}
                textDecoration={"underline"}
                onClick={() => setShowAllFields(true)}
                cursor={"pointer"}
              >
                {t("show_all_fields")}
              </Text>
            )}

            {showAllFields && (
              <motion.div
                initial="hidden"
                animate="visible"
                variants={defaultTransitionVariant}
                style={{
                  flex: 1,
                }}
              >
                <Controller
                  name={"notes"}
                  control={control}
                  render={({ field, formState: { errors } }) => (
                    <InputResponse
                      title={t("notes_text_modal_response_screen")}
                      placeholder={t("placeholder_notes")}
                      tooltip_text={t("notes_tooltip")}
                      {...field}
                    />
                  )}
                />

                <Controller
                  name={"attachments"}
                  control={control}
                  render={({
                    field: { value, onChange },
                    formState: { errors },
                  }) => (
                    <ImagePicker
                      onInsertImage={(file) => {
                        if (file) {
                          const newValue: any = value;
                          newValue.push(file);
                          onChange(newValue);
                        }
                      }}
                      onDeleteImage={(file) => {
                        const newValue = value.filter((val) => val !== file);
                        onChange(newValue);
                      }}
                      value={value}
                    />
                  )}
                />
              </motion.div>
            )}
          </motion.div>
        </VStack>
      ) : (
        <></>
      )}

      <ChakraModal isOpen={confirmClose} onClose={() => setConfirmClose(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t("confirm_modal_close_title")}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{t("confirm_modal_close_text")}</ModalBody>

          <ModalFooter>
            <Button
              mr={3}
              onClick={() => setConfirmClose(false)}
              padding={"0px 16px"}
              background={primary_collor ? primary_collor : "#000000"}
            >
              Cancel
            </Button>
            <Button
              onClick={() => handleCloseModal()}
              padding={"0px 16px"}
              background={"transparent"}
              color={primary_collor ? primary_collor : "#000000"}
              borderColor={primary_collor ? primary_collor : "#000000"}
            >
              Continue
            </Button>
          </ModalFooter>
        </ModalContent>
      </ChakraModal>
    </Modal>
  );
};

export default ResponseForm;
