import { Image, VStack, Text, HStack, Icon } from "@chakra-ui/react";
import React from "react";
import { StyledResponseButton } from "../ResponseForm/SelectQuestion/styles";
import { IoIosArrowForward } from "react-icons/io";

import { Props } from "./types";
import i18next from "../../../i18n";

const ResponseCard: React.FC<Props> = ({
  response,
  onSelect,
  primary_collor,
  questionIndex,
}: Props) => {
  const currentLanguage = i18next.languages[0];

  const createImageFromInitials = (
    size: number,
    name: string,
    color: string
  ) => {
    if (name == null) return;

    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    canvas.width = canvas.height = size;

    if (context) {
      context.fillStyle = "#ffffff";
      context.fillRect(0, 0, size, size);

      context.fillStyle = `${color}`;
      context.fillRect(0, 0, size, size);

      context.fillStyle = "#ffffff";
      context.textBaseline = "middle";
      context.textAlign = "center";
      context.font = `${size / 2}px Inter, sans-serif`;
      context.fillText(name, size / 2, size / 2);
    }

    return canvas.toDataURL();
  };

  return (
    <StyledResponseButton onClick={() => onSelect && onSelect(response)}>
      <HStack>
        <Image
          src={createImageFromInitials(
            48,
            questionIndex,
            primary_collor ? primary_collor : "#000000"
          )}
          borderRadius={"50%"}
          width={"48px !important"}
        />
        <VStack
          marginLeft={"12px"}
          overflow={"hidden"}
          alignItems={"left"}
          maxHeight={"48px"}
          maxW={"252px"}
          display={"grid"}
        >
          <Text
            fontSize={"16px"}
            fontWeight={"600"}
            textAlign={"left"}
            whiteSpace={"nowrap"}
            textOverflow={"ellipsis"}
            overflow={"hidden"}
          >
            {(response.question_identifier &&
              (
                response.question_identifier.content.find(
                  (question) => question.lang === currentLanguage
                ) ||
                response.question_identifier.content.find(
                  (question) => question.is_main
                )
              )?.title) ||
              "Error."}
          </Text>

          <Text
            fontSize={"14px"}
            fontWeight={"400"}
            textAlign={"left"}
            color={"place_holder_texts"}
            marginTop={"4px !important"}
            textOverflow={"ellipsis"}
            overflow={"hidden"}
          >
            {response.text}
          </Text>
        </VStack>
      </HStack>

      <Icon
        as={IoIosArrowForward}
        size={"12px !important"}
        color={"place_holder_texts"}
      />
    </StyledResponseButton>
  );
};

export default ResponseCard;
