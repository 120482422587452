import React from "react";
import useDeviceDetection from "../../../../hooks/IsMobile";
import {
  ChakraProvider,
  Checkbox,
  extendTheme,
  HStack,
  Icon,
  Text,
} from "@chakra-ui/react";
import { StyledModal, StyledModalContent } from "./styles";
import { Props } from "./types";
import { IoMdClose } from "react-icons/io";
import { useTranslation } from "react-i18next";
import { BsTrash } from "react-icons/bs";
import Button from "../../../../components/base/Button";

export const Modal = ({
  isHideCloseButton = false,
  primary_collor,
  ...props
}: Props) => {
  const { isMobile } = useDeviceDetection();
  const { t } = useTranslation();

  const Theme = extendTheme({
    fonts: {
      heading: `'Inter', sans-serif`,
      body: `'Inter', sans-serif`,
    },
    colors: {
      [primary_collor || "#000000"]: {
        500: primary_collor,
      },
    },
  });

  return props.isOpen ? (
    <StyledModal isMobile={isMobile}>
      <StyledModalContent {...props} isMobile={isMobile}>
        <HStack
          padding={"24px"}
          alignItems={"center"}
          justifyContent={"space-between"}
          borderBottom={"1px solid #F5F5F5"}
          width={"100%"}
          background={"white"}
          mr={"12px"}
        >
          <HStack>
            <Icon
              onClick={props.onClose}
              as={IoMdClose}
              color={"#000000"}
              w={"24px !important"}
              h={"24px !important"}
              cursor={"pointer"}
            />
          </HStack>
          <HStack>
            {props.onContinue && (
              <Button
                onClick={props.onContinue}
                background={primary_collor ? primary_collor : "#000000"}
                padding={"0px 16px"}
              >
                {t("response_modal_add_another")}
              </Button>
            )}
            {props.toRemove && (
              <Button
                onClick={props.toRemove}
                marginRight={"8px"}
                background={primary_collor ? primary_collor : "#000000"}
                padding={"12px"}
              >
                <Icon as={BsTrash} size={"12px !important"} />
              </Button>
            )}

            <Button
              onClick={props.save}
              background={primary_collor ? primary_collor : "#000000"}
              padding={"0px 16px"}
            >
              {t("response_modal_save_button")}
            </Button>
          </HStack>
        </HStack>

        {props.children}
      </StyledModalContent>
    </StyledModal>
  ) : (
    <></>
  );
};
