import { HStack, Image, Text } from "@chakra-ui/react";
import React from "react";
import knowmadLogo from "../../assets/images/futures-logo.svg";
import useDeviceDetection from "../../hooks/IsMobile";

type Props = {
  primary_collor?: string;
};

const Header: React.FC<Props> = ({ primary_collor }: Props) => {
  const { isMobile } = useDeviceDetection();

  return (
    <HStack
      padding={"16px 18px"}
      bg={primary_collor ? primary_collor : "black"}
      width={"100%"}
      justifyContent={"space-between"}
      borderRadius={isMobile ? "0px" : "16px 16px 0px 0px"}
    >
      <Text
        fontWeight={500}
        fontSize={"16px"}
        color={"white"}
        fontFamily={`'Roboto Serif', serif`}
      >
        Knowmad Café
      </Text>

      <Image src={knowmadLogo} height={"24px"} />
    </HStack>
  );
};

export default Header;
