import styled from "styled-components";

export const StyledInput = styled.textarea`
  margin-top: 8px;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  outline: none;
  border: none;
  height: 72px;
  resize: none;
`;
