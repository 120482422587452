import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { GlobalStyle } from "./assets/style/global.styles";
import Router from "./routes";
import "./i18n";
import { ChakraProvider } from "@chakra-ui/react";
import lightTheme from "./assets/theme/light";
import ProjectContextProvider from "./contexts/project-context";
import { BrowserRouter } from "react-router-dom";
import ApplicationWrapper from "./components/ApplicationWrapper";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ChakraProvider theme={lightTheme}>
        <ProjectContextProvider>
          <ApplicationWrapper>
            <GlobalStyle />
            <Suspense fallback={"Loading you language.."}>
              <Router />
            </Suspense>
          </ApplicationWrapper>
        </ProjectContextProvider>
      </ChakraProvider>
    </BrowserRouter>
  </React.StrictMode>
);

serviceWorkerRegistration.register();
