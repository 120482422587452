import { initReactI18next } from "react-i18next";
import i18next from "i18next";
import enTranslation from "./langs/en";
import ptTranslation from "./langs/pt";
import esTranslation from "./langs/es";
import czTranslation from "./langs/cz";

export const resources = {
  en: { translation: enTranslation },
  pt: { translation: ptTranslation },
  es: { translation: esTranslation },
  cz: { translation: czTranslation },
};

i18next.use(initReactI18next).init({
  resources,
  lng: "en",
  fallbackLng: "en",
  interpolation: { escapeValue: false },
});

export default i18next;
