import { HStack, Icon } from "@chakra-ui/react";
import Select from "react-select";

import React, { useContext } from "react";
import i18next, { resources } from "../../i18n";
import { BsGlobe } from "react-icons/bs";
import { ProjectContext } from "../../contexts/project-context";
import { useTranslation } from "react-i18next";

const LanguageSelect: React.FC = () => {
  const { t } = useTranslation();
  const { projectSelected } = useContext(ProjectContext);
  const resourcesList: any = Object.keys(resources).map((val) => ({
    value: val,
    label: val.toUpperCase(),
  }));
  const currentLanguage = i18next.languages[0];

  const customStyles = {
    option: (provided: any, state: any) => ({
      ...provided,

      background: state.isFocused
        ? projectSelected?.primary_collor
          ? projectSelected.primary_collor
          : "#000000"
        : "transparent",
      color: state.isFocused
        ? "#ffffff"
        : projectSelected?.primary_collor
        ? projectSelected.primary_collor
        : "#000000",
    }),
    control: (provided: any, state: any) => ({
      ...provided,
      borderColor: "none !important",
      borderWidth: "none !important",
      boxShadow: "none !important",
    }),
  };

  return (
    <HStack>
      <Icon as={BsGlobe} size={"20px"} />
      <Select
        styles={customStyles}
        value={{
          value: currentLanguage,
          label: currentLanguage.toUpperCase(),
        }}
        options={resourcesList}
        onChange={(event) => {
          i18next.changeLanguage(event?.value);
        }}
      />
    </HStack>
  );
};

export default LanguageSelect;
